import {Product} from './product.model';
import {Campaign} from './campaign.model';
import { Step, JsonStep } from './step.model';
import { PurchaseItem, JsonPurchaseItem } from './purchase-item.model';
import { PurchaseAbatement, JsonPurchaseAbatement } from './purchase-abatement.model';
import { Alert } from './alert.model';
import { AlertLevel } from './alert-level.model';

export interface JsonPurchase {
  id?: number;
  created?: string;
  step?: JsonStep;
  title?: string;
  advertiserTemporary?: string;
  product?: Product;
  campaign?: Campaign;
  reference?: string;
  parrainage?: boolean;
  folderNum?: string;
  productTemporary?: string;
  agencyTemporary?: string;
  broadcastStart?: Date;
  broadcastEnd?: Date;
  caNet?: number;
  currency?: string;
  isSigned?: boolean;
  purchaseItem?: JsonPurchaseItem;
  purchaseAbatements?: any[];
  purchaseItems?: JsonPurchaseItem[];
  _embedded?: any;
  creator?: any;
  codutExt?: any;
  alert?: any;
  alertLevel?: any;
  commercial?: any;
  dealType?: number | null;
  purchaseSummary?: {
    runInvoicedAmount?: number | null;
  }
}

export interface PurchaseFilter {
  query?: string;
  broadcastStart?: any;
  broadcastEnd?: any;
  status?: any;
  confidenceIndex?: any;
  alertCheck?: any;
  alertType?: any;
  videoCheck?: any;
  displayCheck?: any;
  opsCheck?: any;
  videoFormat?: any;
  displayFormat?: any;
  dealType?: number;
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
}

export class Purchase {

  public id: number;
  public created: Date;
  public step: Step;
  public title: string;
  public advertiserTemporary: string;
  public agencyTemporary: string;
  public productTemporary: string;
  public product: Product;
  public campaign: Campaign;
  public reference: string;
  public parrainage: boolean;
  public folderNum: string;
  public broadcastStart: Date;
  public broadcastEnd: Date;
  public caNet: number;
  public currency: string;
  public isSigned: boolean;
  public purchaseItem: PurchaseItem;
  public purchaseAbatements: PurchaseAbatement[];
  public abatements: any[];
  public purchaseItems: PurchaseItem[] = [];
  public creator: any;
  public codutExt: any;
  public _embedded: any;
  public alert: Alert;
  public alertLevel: AlertLevel;
  public commercial: any;
  public dealType: number;

  constructor(jsonPurchase: JsonPurchase) {
    this.id = jsonPurchase.id;
    this.advertiserTemporary = jsonPurchase.advertiserTemporary;

    if (jsonPurchase._embedded && jsonPurchase._embedded.step) {
      this.step = new Step(jsonPurchase._embedded.step);
    }

    if (jsonPurchase['purchaseItems']) {
      for (const purchaseItem of jsonPurchase['purchaseItems']) {
        this.purchaseItems.push(new PurchaseItem(purchaseItem));
      }
    }

    this.title = jsonPurchase.title;
    this.productTemporary = jsonPurchase.productTemporary;
    if (jsonPurchase.product) {
      this.product = new Product(jsonPurchase.product);
    }
    if (jsonPurchase.campaign) {
      this.campaign = new Campaign(jsonPurchase.campaign);
    }
    this.reference = jsonPurchase.reference;
    this.parrainage = jsonPurchase.parrainage;
    this.folderNum = jsonPurchase.folderNum;
    this.agencyTemporary = jsonPurchase.agencyTemporary;
    this.broadcastStart = jsonPurchase.broadcastStart;
    this.broadcastEnd = jsonPurchase.broadcastEnd;
    this.caNet = jsonPurchase.caNet;
    this.currency = jsonPurchase.currency;
    this.isSigned = jsonPurchase.isSigned;
    this.dealType = jsonPurchase.dealType;

    if (jsonPurchase._embedded && jsonPurchase._embedded.step) {
      this.step = new Step(jsonPurchase._embedded.step);
    }

    if (jsonPurchase.purchaseItem) {
      this.purchaseItem = new PurchaseItem(jsonPurchase.purchaseItem);
    }

    if (jsonPurchase._embedded && jsonPurchase._embedded.purchaseAbatements) {
      this.purchaseAbatements = jsonPurchase._embedded.purchaseAbatements.map(purchaseAbatement =>
        new PurchaseAbatement(purchaseAbatement)
      );
    }

    if (jsonPurchase.creator) {
      this.creator = jsonPurchase.creator;
    }

    if (jsonPurchase.codutExt) {
      this.codutExt = jsonPurchase.codutExt;
    }

    if (jsonPurchase.alert || jsonPurchase._embedded && jsonPurchase._embedded.alert) {
      this.alert = jsonPurchase._embedded.alert ? new Alert(jsonPurchase._embedded.alert) : new Alert(jsonPurchase.alert);
    }

    if (jsonPurchase.alertLevel || jsonPurchase._embedded && jsonPurchase._embedded.alertLevel) {
      this.alertLevel = jsonPurchase._embedded.alertLevel ?
        new AlertLevel(jsonPurchase._embedded.alertLevel) : new AlertLevel(jsonPurchase.alert);
    }

    if (jsonPurchase.commercial) {
      this.commercial = jsonPurchase.commercial;
    }
  }

  public static init(): Purchase {
    return new Purchase({
      id: 0,
      title: '',
      step: null,
      created: null,
      advertiserTemporary: '',
      agencyTemporary: null,
      currency: null,
      productTemporary: null,
      product: null,
      campaign: null,
      reference: '',
      parrainage: false,
      folderNum: '',
      broadcastStart: null,
      broadcastEnd: null,
      caNet: null,
      purchaseItem: null,
      purchaseAbatements: null,
      purchaseItems: null,
      commercial: null,
      dealType: null
    });
  }
}
