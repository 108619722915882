
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Ut} from '../model/ut.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";

export interface UtFilter {
  name?: string;
  subEmployee?: boolean;
}

@Injectable()
export class UtService {

  private route = '/ut';

  constructor(
    private httpService: HttpClient
  ) {}

  public getList(filter: UtFilter = {}): Observable<Ut[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded'].ut.map(jsonUt => new Ut(jsonUt));
        list['total'] = jsonResponse['total_items'];
        return list;
      }));
  }

  public getCodGrpUT(trigramUT): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/cod-grp-ut' + '?codUT=' + trigramUT;

    return this.httpService.get(url);
  }
}
