export interface JsonFilm {
  id: number;
  title: string;
  numProduit: number;
  encodedFree: boolean;
  provisioningFree: boolean;
  acquittedFree: boolean;
  encodedBouygues: boolean;
  provisioningBouygues: boolean;
  acquittedBouygues: boolean;
  encodedOrange: boolean;
  provisioningOrange: boolean;
  acquittedOrange: boolean;
  encodedSfr: boolean;
  provisioningSfr: boolean;
  acquittedSfr: boolean;
  encodedSfrNc: boolean;
  provisioningSfrNc: boolean;
  acquittedSfrNc: boolean;
  isFullEncoded: boolean;
  duration: number;
}

export class Film {
  public id: number;
  public title: string;
  public numProduit: number;
  public encodedFree: boolean;
  public provisioningFree: boolean;
  public acquittedFree: boolean;
  public encodedBouygues: boolean;
  public provisioningBouygues: boolean;
  public acquittedBouygues: boolean;
  public encodedOrange: boolean;
  public provisioningOrange: boolean;
  public acquittedOrange: boolean;
  public encodedSfr: boolean;
  public provisioningSfr: boolean;
  public acquittedSfr: boolean;
  public encodedSfrNc: boolean;
  public provisioningSfrNc: boolean;
  public acquittedSfrNc: boolean;
  public isFullEncoded: boolean;
  public duration: number;

  constructor(jsonFilm: JsonFilm) {
    this.id = jsonFilm.id;
    this.title = jsonFilm.title;
    this.numProduit = jsonFilm.numProduit;
    this.encodedFree = jsonFilm.encodedFree;
    this.provisioningFree = jsonFilm.provisioningFree;
    this.acquittedFree = jsonFilm.acquittedFree;
    this.encodedBouygues = jsonFilm.encodedBouygues;
    this.provisioningBouygues = jsonFilm.provisioningBouygues;
    this.acquittedBouygues = jsonFilm.acquittedBouygues;
    this.encodedOrange = jsonFilm.encodedOrange;
    this.provisioningOrange = jsonFilm.provisioningOrange;
    this.acquittedOrange = jsonFilm.acquittedOrange;
    this.encodedSfr = jsonFilm.encodedSfr;
    this.provisioningSfr = jsonFilm.provisioningSfr;
    this.acquittedSfr = jsonFilm.acquittedSfr;
    this.encodedSfrNc = jsonFilm.encodedSfrNc;
    this.provisioningSfrNc = jsonFilm.provisioningSfrNc;
    this.acquittedSfrNc = jsonFilm.acquittedSfrNc;
    this.isFullEncoded = jsonFilm.isFullEncoded;
    this.duration = jsonFilm.duration;
  }

  public isAllEncoded() : boolean {
    return this.isFullEncoded;
  }

  public isAllProvisioning() : boolean {
    return (this.provisioningSfr &&
            this.encodedFree &&
            this.provisioningOrange &&
            this.provisioningSfrNc &&
            this.provisioningBouygues);
  }

  public isAllAcquitted() : boolean {
    return (this.acquittedSfr &&
            this.encodedFree &&
            this.acquittedOrange &&
            this.acquittedSfrNc &&
            this.acquittedBouygues);
  }
}
