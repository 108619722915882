import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PurchaseAbatmentDialogComponent } from '../purchase-abatment-dialog/purchase-abatment-dialog.component';
import {Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef} from '@angular/core';
import { JsonPurchase, Purchase } from '../../core/model/purchase.model';
import {AudienceService} from '../../core/service/audience.service';
import {PurchaseService} from '../../core/service/purchase.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import {DashboardPurchaseComponent} from '../../dashboard/dashboard-purchase/dashboard-purchase.component';
import {PurchaseHistoryDialogComponent} from '../purchase-history-dialog/purchase-history-dialog.component';
import {forkJoin, Observable, Observer, SubscriptionLike as ISubscription} from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { Router } from '@angular/router';
import { AppState, SetTypeItem, ResetPurchase, StartGetPurchase, ResetItems, GetPurchaseSuccess } from '../../store';
import { Store } from '@ngrx/store';
import { AppConstants } from '../../app.constants';
import { ConsistencyModel } from '../../core/model/consistency.model';
import { StepService } from '../../core/service/step.service';
import { MessageDialogComponent } from '../shared/message-dialog/message-dialog.component';
import { PurchaseCommentComponent } from '../purchase-comment/purchase-comment.component';
import { CommentService } from '../../core/service/comment.service';
import { MessageLoadingComponent } from '../shared/message-loading/message-loading.component';
import { AlertService } from '../../core/service/alert.service';
import { AlertDisplay } from '../../core/model/alert-display.model';
import { PurchaseDetailReorderDialogComponent } from './purchase-detail-reorder-dialog/purchase-detail-reorder-dialog.component';
import { PurchaseDetailBilanModalComponent } from './purchase-detail-bilan-modal/purchase-detail-bilan-modal.component';
import { UtService } from '../../core/service/ut.service';
import { Ut } from '../../core/model/ut.model';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {DealType} from '../../core/model/deal.model';
import { PurchaseSyncCreativeDialogComponent } from '../../purchase/purchase-creative-dialog/purchase-sync-creative-dialog.component';

@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.scss']
})
export class PurchaseDetailComponent implements OnInit {

  public listForm: FormGroup;
  public signedForm: FormControl;
  public purchase$: Observable<JsonPurchase>;
  public purchaseItems: any;
  public purchaseItemsList: any;
  public displayFilter: any[];
  public criterionFilter: any = '';
  public purchaseId: number = null;
  public loadingItems: boolean = true;
  public modalTitle: string;
  private product: any = null;
  public campaign: any = null;
  private broadcastStart: Date = null;
  private broadcastEnd: Date = null;
  public savingCa: boolean = false;
  public exporting: boolean = false;
  public caConsitent: ConsistencyModel = { isConsistent: true };
  public currentPurchase: JsonPurchase;
  public disabing: boolean = false;
  public nbComments: number;
  public syncing: boolean = false;
  public syncData: object = null;
  public syncEquativData: boolean = false;
  public synced: boolean = false;
  public advertiserTemporary: string = '';
  public productTemporary: string = '';
  public title: string = '';
  public alertsDisplay: AlertDisplay[] = [];
  public purchaseDetailForm: FormGroup;
  public editingCommercial: boolean = false;
  public saving: boolean;
  public itemsNotValid: string;
  public loadingUt = false;
  public uts$: Observable<Ut[]>;
  public editing: boolean = false;
  public editingHideData: boolean = false;
  public leftPanelRowSpan = 5;
  public channel_agreement = '';
  public agreement : boolean = false;
  public exchange : boolean = false;
  public allRowHeight = 61;
  public savingComercialInfo = false;
  public dealTypes: DealType[] | null;
  public runInvoicedAmount = 0;

  protected readonly AppConstants = AppConstants;

  @ViewChild('utAutocompleteElem', {static: false}) utAutocompleteElem: ElementRef;

  constructor(private purchaseService: PurchaseService,
              private audienceService: AudienceService,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              public snackBar: MatSnackBar,
              private fb: FormBuilder,
              private route: Router,
              private commentService: CommentService,
              private store: Store<AppState>,
              private stepService: StepService,
              private alertService: AlertService,
              private utService: UtService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params && params['id']) {
        this.purchaseId = params['id'];
        this.getAlerts();
      }
    });
    this.initDealTypes();
    this.initForm();
    this.getPurchase();
    this.initValueChanges();
    this.getCurrentPurchase();
    this.initValues();
    this.initPurchaseItemsList();
    this.checkConsistency();

    this.commentService.getNumberComments(this.purchaseId)
      .subscribe((nbComments) => this.nbComments = nbComments);

    this.startUtAutocomplete();
  }

  public haveItems(purchaseItems: any): boolean {
    if (purchaseItems) {
      return (   purchaseItems['ops'].length > 0
              || purchaseItems['display'].length > 0
              || purchaseItems['video'].length > 0
              || purchaseItems['segmentalTv'].length > 0
              || purchaseItems['donationTechCost'].length > 0);
    }
    return false;
  }

  public isLoading(purchase): boolean {
    return (purchase.id == null);
  }

  public search(item: any): boolean {
    if (this.listForm.get('filterTitle').value) {
      return item.purchaseItem.title &&
        item.purchaseItem.title.toLowerCase().includes(
          this.listForm.get('filterTitle').value.toLowerCase()
        );
    }
    return true;
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(DashboardPurchaseComponent, {
      width: '700px',
      height: '750px',
      disableClose: true,
      data: {
        purchase : this.purchase$,
        purchaseItemsList : this.purchaseItemsList,
        product : this.product,
        campaign : this.campaign,
        broadcastStart: this.broadcastStart,
        broadcastEnd: this.broadcastEnd,
        modalTitle: this.modalTitle,
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Modifier la vente'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.checkConsistency();
      this.getAlerts();
      this.initValues();
      this.initPurchaseItemsList();
    });

  }

  private checkConsistency(): void {
    this.purchaseService.checkConsistency(this.purchaseId).subscribe(
      (data) => {
        if (data) { this.caConsitent = data['consistency']; }
      }
    );
  }

  public openHistoryDialog(): void {
    const dialogRef = this.dialog.open(PurchaseHistoryDialogComponent, {
      width: '600px',
      disableClose: true,
    });
  }

  public openCommentDialog(): void {
    const dialogRef = this.dialog.open(PurchaseCommentComponent, {
      width: '600px',
      disableClose: true,
      data: {
        purchaseId: this.purchaseId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.action == 'close_comment') {
        this.nbComments = result.nbComments;
        this.getPurchase(false);
      }
    });
  }

  /**
   * Disable purchase
   * @param id
   */
  private abort(id): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: '',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        confirmQuestion: false,
        message: 'Confirmez-vous l\'abandon de cette propale ?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'save') {
        this.disabing = true;
        this.purchaseService.delete(id)
          .subscribe(() => {
            this.disabing = false;
            this.route.navigate(['/dashboard']);
            this.openSnackBar('La propale a bien été désactivée');
          }, (error) => {
            this.disabing = false;
          });
      }
    });
  }

  public openSnackBar(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    const snackBarRef$ = this.snackBar.open(
      message,
      action,
      {
        duration: 6000,
        verticalPosition: 'top',
      });
      return snackBarRef$;
    }

  public addOpsItem(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param['id']) {
        this.store.dispatch(new SetTypeItem('ops'));
        this.route.navigate(['/purchase/' + param.id + '/ops']);
      }
    });
  }

    public addDisplayItem(): void {
      this.activatedRoute.params.subscribe(param => {
        if (param && param['id']) {
          this.store.dispatch(new SetTypeItem(AppConstants.typeDisplay));
          this.route.navigate(['/purchase/' + param.id + '/display']);
        }
      });
    }

    public addVideoItem(): void {
      this.activatedRoute.params.subscribe(param => {
        if (param && param['id']) {
          this.store.dispatch(new SetTypeItem(AppConstants.typeVideo));
          this.route.navigate(['/purchase/' + param.id + '/video']);
        }
      });
    }

  public addSegmentalTvItem(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param['id']) {
        this.store.dispatch(new SetTypeItem(AppConstants.typeSegmentalTv));
        this.route.navigate(['/purchase/' + param.id + '/segmental-tv']);
      }
    });
  }

  public addDonationAndTechCostItem(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param['id']) {
        this.store.dispatch(new SetTypeItem(AppConstants.typeDonationTechCost));
        this.route.navigate(['/purchase/' + param.id + '/donation-tech-cost']);
      }
    });
  }

  public edit(item: any): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param['id']) {
        const purchaseItem = item.purchaseItem;
        const type = (purchaseItem.type === 4) ? 'donation-tech-cost' :
          (purchaseItem.type === 3) ? 'segmental-tv' :
          (purchaseItem.type === 2) ? 'ops' :
          (purchaseItem.type === 1) ? 'display' :
           'video';
          this.route.navigate(['/purchase/' + param.id + '/' + type + '/' + item.id]);
        }
      });
    }

  /**
   * Export purchase to xlsx/pdf
   * @param {number} purchaseId
   */
  public export(purchaseId: number, lang: string, format: string = 'xlsx'): void {
    let isValidToExport: boolean = true;
    if (format === 'pdf') {
      isValidToExport = this.isValidPurchaseForPdf();
      if (!isValidToExport) {
        this.openSnackBar('Veuillez renseigner les informations de produit et campagne !');
      }
    }
    if (isValidToExport) {
      this.exporting = true;
      this.purchaseService.downloadPurchase(purchaseId, format, lang,
        (format === 'pdf') ? purchaseId + '_' + this.title : 'proposition_commerciale_')
        .subscribe((res) => {
          this.purchaseService.downloadFile(res);
          this.exporting = false;
        });
    }
  }

  public saveCa(): void {
    this.savingCa = true;
    const subConsistency: ISubscription =  this.store.select('currentPurchase').subscribe(
      (purchase) => {
        this.purchaseService.update(Object.assign(
          {},
          purchase, {
            caNet: this.caConsitent.total
          }
        ), ['abatements', 'step', 'campaign', 'currency'
          ,'product_temporary', 'advertiser_temporary', 'agency_temporary']).subscribe(
          (data) => {
            this.savingCa = false;
            subConsistency && subConsistency.unsubscribe();
            this.store.dispatch(new GetPurchaseSuccess(data));
            this.caConsitent.isConsistent = true;
            this.checkConsistency();
          }, (error) => {
              this.savingCa = false;
          });
      }
    );
  }

  /**
   * Event from items
   * @param event
   */
  public itemEvent(event: any): void {
    if (event) {
      switch (event.operation) {
        case 'reload':
          this.getPurchase(false);
          this.checkConsistency();
          break;
        case 'reload-alerts':
          this.getAlerts();
          break;
        default:
          break;
      }
    }
  }

  private getPurchase(force: boolean = true): void {
    this.activatedRoute.params.subscribe(params => {
      if (params && params['id']) {
        force && this.store.dispatch(new ResetPurchase());
        force && this.store.dispatch(new ResetItems());
        this.store.dispatch(new StartGetPurchase(params['id']));
      }
    });
  }

  public openAbatmentDialog(): void {
    const dialogRef = this.dialog.open(PurchaseAbatmentDialogComponent, {
      width: '800px',
      disableClose: true,
      data: {
        typeAbatement: 'purchase',
        purchase: this.purchase$
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      this.purchase$.subscribe(purchase => {
        if (!result && !result.purchaseAbatements) {
          return;
        }

        purchase.purchaseAbatements = result.purchaseAbatements;

        const dialogRef = this.dialog.open(MessageLoadingComponent, {
          width: '800px',
          disableClose: true,
          data: {
            modalTitle: 'Sauvegarde des abattements',
            message: 'Veuillez patientez ...'
          }
        });

        this.purchaseService.update(purchase)
        .subscribe(purchaseResponse => {
          this.getPurchase();
          dialogRef.close();
        });
      })
      .unsubscribe();
    });
  }

  public haveVideoItems(): boolean {
    return this.purchaseItems.video.length > 0;
  }

  public haveSegmentalTvItems(): boolean {
    return this.purchaseItems.segmentalTv.length > 0;
  }

  public haveDisplayItems(): boolean {
    return this.purchaseItems.display.length > 0;
  }

  public haveProgrammaticItems(): boolean {
    return this.currentPurchase.dealType === AppConstants.purchase.programmatic.isProgrammatic;
 }


  public sync(destination: string): void {
    switch (destination) {
      case 'adservers':
        // To reactivate leter, when AdOps will be ready
        // if ((this.haveVideoItems() || this.haveSegmentalTvItems()) && !this.haveProgrammaticItems()) {
        //   this.syncCreatives(this.purchaseItems).subscribe();
        // }
        if (this.haveDisplayItems()) {
          this.syncEquativ().subscribe();
        }
        this.syncAds().subscribe();
        break;
      case 'films':
        if ((this.haveVideoItems() || this.haveSegmentalTvItems()) && !this.haveProgrammaticItems()) {
          this.syncFilms(this.purchaseItems).subscribe();
        }
        break;
      case 'creatives':
        this.syncCreatives().subscribe();
        break;
      case 'old_model':
        this.mergeInOldModel().subscribe();
        break;
    }
  }

  private syncFilms($purchaseItems): Observable<any> {
    if (this.isValidPurchaseItems() && this.isFilmToSync()) {
      const dataToPass = {
        targetId: this.purchaseId,
        targetService: 'purchaseService',
        purchaseItems: $purchaseItems
      };
      const dialogRef = this.dialog.open(PurchaseSyncCreativeDialogComponent, {
        width: '600px',
        disableClose: true,
        data: dataToPass
      });
  
      return dialogRef.afterClosed();
    } else {
      return new Observable(observer => observer.next('Invalid purchase item, or no film to sync'));
    }
  }

  private syncEquativ(): Observable<any> {
    if (this.isValidPurchaseItems()) {
      this.syncing = true;
      this.synced = false;

      return new Observable(
        observer => {
          this.purchaseService
            .syncEquativ(this.purchaseId)
            .subscribe(
              (response) => {
                this.syncing = false;
                this.syncEquativData = true;
                observer.next(response);
              },
              error => {
                this.syncing = false,
                observer.error(this.catchError(error))
              }
            );
        }
      );
    } else {
      return Observable.create(observer => observer.next('Invalid purchase item'));
    }
  }

  private syncAds(campaignTest: boolean = false): Observable<any> {
    if (this.isValidPurchaseItems()) {
      this.syncing = true;
      this.synced = false;
      
      return new Observable(
        observer => {
          this.purchaseService
            .syncAds(this.purchaseId, campaignTest)
            .subscribe(
              (data) => {
                this.synced = true;
                this.syncing = false;
                this.syncData = data ? data['syncAd'] : null;
                if (this.currentPurchase.dealType === 1) { this.getPurchase(); }
                observer.next(data);
              },
              error => {
                this.syncing = false,
                observer.error(this.catchError(error))
              }
            );
        });
    } else {
        return Observable.create(observer => observer.next('Invalid purchase item'));
    }
  }

  private syncCreatives(): Observable<any> {
    if (this.isValidPurchaseItems()) {
      this.syncing = true;
      this.synced = false;

      return new Observable(
        observer => {
          this.purchaseService
            .syncCreatives(this.purchaseId)
            .subscribe(
              (response) => {
                this.syncing = false;
                this.syncEquativData = true;
                observer.next(response);
              },
              error => {
                this.syncing = false,
                observer.error(this.catchError(error))
              }
            );
        }
      );
    } else {
      return Observable.create(observer => observer.next('Invalid purchase item'));
    }
  }

  private mergeInOldModel(): Observable<any> {
    if (this.isValidPurchaseItems()) {
      this.syncing = true;

      return Observable.create(
        observer => {
          this.purchaseService
            .mergeInOldModel(this.purchaseId)
            .subscribe(
              (response) => {
                this.syncing = false;
                observer.next(response);
              },
              error => {
                this.syncing = false;
                observer.error(this.catchError(error));
              }
            );
        }
      );
    } else {
      return Observable.create(observer => observer.next('Invalid purchase item'));
    }
  }

  private initForm(): void {
    this.displayFilter = [
      {
        value: '',
        title: 'Tous les dispositifs'
      },
      {
        value: 0,
        title: 'Tous les dispositifs vidéo'
      },
      {
        value: 1,
        title: 'Tous les dispositifs display'
      },
      {
        value: 2,
        title: 'Tous les dispositifs Tv Segmentés'
      },
      {
        value: 3,
        title: 'Tous les dispositifs donation & frais techniques'
      },
      {
        value: 4,
        title: 'Tous les autres dispositifs'
      }
    ];

    this.listForm = this.fb.group({
      'filterType': [this.displayFilter[0]],
      'filterTitle': ['']
    });

    this.listForm.get('filterType')
    .valueChanges
    .subscribe((val) => {
      this.getPurchaseItemsByType(val.value);
      this.criterionFilter = val.value;
    });

    this.purchaseDetailForm = this.fb.group({
      id: [''],
      // title: [''],
      // advertiserTemporary: [''],
      // product: [''],
      broadcastStart: [
        '',
        [Validators.required]
      ],
      broadcastEnd: [
        '',
        [Validators.required]
      ],
      commercial: [
        '',
        []
      ],
    });
  }

  public changeStep(): void {
    const currentStep = this.currentPurchase._embedded.step.id;
    switch (currentStep) {
      case '1':
          if (this.isValidPurchaseItems()) {
            this.getNextStep('2').subscribe();
          }
        break;
      case '2':
          this.getNextStep('3')
            .subscribe();
          break;
      case '3': break;
      case '4': break;
      case '5': break;
      case '6': this.getNextStep('5')
        .subscribe();
      break;
      default: break;
    }
  }

  /**
   * Get all alerts of purchase
   */
  public getAlerts(): void {
    this.alertService.getAlerts(this.purchaseId)
      .subscribe(alerts => {
        this.alertsDisplay = alerts;
      });
  }

  /**
   * @param alertId
   */
  public closeAlert(alertId: number): void {
    this.alertsDisplay = this.alertsDisplay.filter((v, k) => {
      return (v && v.id !== alertId);
    });
    this.alertService.delete(alertId)
      .subscribe(alerts => {
      });
  }

  /**
   * @param alert
   */
  public getClassAlert(alert: any): string {
    if (alert.level.level === AppConstants.alertLevel.error) {
      return 'alert-error';
    }
    return 'alert-warning';
  }

  /**
   * @param alert
   */
  public getIconAlert(alert: any): string {
    if (alert.level.level === AppConstants.alertLevel.error) {
      return 'error_outline';
    }
    return 'warning';
  }

  private isValidPurchaseItems(): boolean {
        if (   ! this.checkIfItemsBeforeOI()
        || ! this.checkCABeforeOI()
        || ! this.checkItemsBeforeOI(this.purchaseItems.display)
        || ! this.checkItemsBeforeOI(this.purchaseItems.video)
        || ! this.checkItemsBeforeOI(this.purchaseItems.segmentalTv)) {
      return false;
    }
    return true;
  }

  private isFilmToSync(): boolean {

    for (const item of this.purchaseItemsList) {
      if (item.films && item.films.length > 0) {
        return true;
      }
    }
    return true;
  }

  private checkIfItemsBeforeOI(): boolean {
    if (   this.purchaseItems.display.length === 0
        && this.purchaseItems.video.length === 0
        && this.purchaseItems.ops.length === 0
        && this.purchaseItems.donationTechCost.length === 0
        && this.purchaseItems.segmentalTv.length === 0) {
      this.openSnackBar('La proposition commerciale doit contenir au moins un dispositif');
      return false;
    }
    return true;
  }

  private checkCABeforeOI(): boolean {
    if (! this.caConsitent.isConsistent && (!this.currentPurchase?.dealType)) {
      this.openSnackBar('Le CA global est incohérent avec la somme des CA des dispositifs');
      return false;
    }
    return true;
  }

  private checkItemsBeforeOI(items: any): boolean {
    for (const item of items) {
      if (item.formats.length <= 0
        || (!(item.purchaseItem.type === AppConstants.typeSegmentalTv) && (item.ranges && item.ranges.length <= 0))
        || (item.purchaseItem.type === AppConstants.typeSegmentalTv
          && (item.segmentalRanges && item.segmentalRanges.length <= 0))) {
        this.openSnackBar('Le dispositif \"' + item.purchaseItem.title + '\" est incomplet ou invalide');
        return false;
      }
      if (item.purchaseItem.broadcastStart < this.currentPurchase.broadcastStart
          || item.purchaseItem.broadcastEnd > this.currentPurchase.broadcastEnd) {
        this.openSnackBar('Le dispositif \"' + item.purchaseItem.title + '\" a un problème de dates');
        return false;
      }
    }
    return true;
  }

  private getNextStep(idNextStep: string): Observable<any> {
    const currentPurchase = this.purchase$;
    let dialogRef: any = null;
    return Observable.create(
      observer => {
          this.stepService.getStep(idNextStep).
            subscribe(nextStep => {
              if (nextStep) {
                this.currentPurchase._embedded.step = nextStep;
                let currentPurchase = this.currentPurchase;

                if (idNextStep == '3') {
                  dialogRef = this.openWaitingPopup();
                }

                this.purchaseService.update(this.currentPurchase, ['abatements', 'campaign']).
                  subscribe(
                    data => {
                      this.store.dispatch(new GetPurchaseSuccess(data));
                      if (idNextStep == '3' && dialogRef) {
                        dialogRef.close();
                        this.getPurchase();
                      }
                      if (idNextStep == '5') {
                        this.snackBar.open('Modification de l\'achat. L\'achat est désormais en mode live',
                          null, { duration: 2000, verticalPosition: 'top'});

                      }
                      observer.next(data);
                    },
                    error => {
                      if (idNextStep == '3' && dialogRef) {
                        dialogRef.close();
                        this.getPurchase();
                      }
                      this.manageStepChangeError(error, currentPurchase);
                    }
                  );
              }
            });
      });
  }

  private manageStepChangeError(error, currentPurchase) {
    let snackBarRef;

    switch (error.error.status) {
      case 424:
        try {
          const parsedDetail = JSON.parse(error.error.detail);
          snackBarRef = this.openSnackBar(parsedDetail['msg']);
          if (parsedDetail['purchaseItemId']) {
            this.itemsNotValid = parsedDetail['purchaseItemId'];
          }
        } catch (e) {
          snackBarRef = this.openSnackBar(error.error.detail);
        }
        this.getPurchase();
        break;
      case 429:
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          width: '600px',
          disableClose: true,
          data: {
            modalTitle: '',
            modalBtnCancel: 'Non',
            modalBtnValid: 'Oui',
            confirmQuestion: false,
            message: "Certains Forecasts sont incorrects ou n'ont pas été synchronisés, voulez-vous forcer le passage à l'étape 3 ?"
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            const dialogRef = this.openWaitingPopup();

            currentPurchase._embedded.step.forceStepChange = true;
            this.purchaseService
              .update(currentPurchase, ['abatements', 'campaign'])
              .subscribe(data => {
                dialogRef.close();
                this.getPurchase();
              });
          }
        });
        break;
      default :
        snackBarRef = this.openSnackBar(error.error.detail);
        this.getPurchase();
        break;
    }
  }

  private openWaitingPopup(): any {
    return this.dialog.open(MessageLoadingComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: 'Synchronisation en cours',
        message: 'Patientez ...'
      }
    });
  }

  private getCurrentPurchase(): void {
    this.store.select('currentPurchase').subscribe(
    purchase => {
      this.currentPurchase = purchase;
      this.signedForm.patchValue(purchase.isSigned, {emitEvent: false});
      }
    );
  }

  private initValues(): void {
    this.purchase$ = this.store.select('currentPurchase');
    this.purchase$.subscribe(res => {
      if (res._embedded && res._embedded.product) this.product = res._embedded.product;
      if (res._embedded && res._embedded.campaign) this.campaign = res._embedded.campaign;
      if (res._embedded && res._embedded.step) this.modalTitle = res._embedded.step.modal_title;

      this.broadcastStart = res.broadcastStart;
      this.broadcastEnd = res.broadcastEnd;
      this.productTemporary = res.productTemporary;
      this.advertiserTemporary = res.advertiserTemporary;
      this.title = res.title;
      (res && res._embedded && res._embedded.commercial) ? this.editingCommercial = false : this.editingCommercial = true;
    });
  }

  private initPurchaseItemsList(): void {
    this.store.select('currentPurchaseItems').subscribe(
      list => {
        this.loadingItems = false;
        this.purchaseItems = list;
        this.purchaseItemsList = this.sortPurchaseItems(list);
        this.computeRunningData();
      }
    );
  }

  /**
   * Check if purchase is valid for generation of pdf io
   */
  private isValidPurchaseForPdf(): boolean
  {
    const haveProductCampaign = (this.product && this.campaign) ||
                    (this.productTemporary && this.advertiserTemporary);
    return (this.isValidPurchaseItems() && haveProductCampaign);
  }

  private syncAndDisable() {
      const dialogRef = this.dialog.open(MessageLoadingComponent, {
          width: '600px',
          disableClose: true,
          data: {
            modalTitle: 'Synchronisation en cours',
            message: 'Patientez ...'
          }
        });

        this.syncAds()
          .subscribe(
            result => {
              if (result.syncAd.freewheel.synced || result.syncAd.dfp.synced) {
                dialogRef.close();
              }
            }
          );
  }

  private catchError(error: any): void {
    switch (error.status) {
      case 404:
       this.snackBar.open('Erreur lors de la synchronisation', null, { duration: 2000, verticalPosition: 'top'});
        break;
      default:
        this.snackBar.open('Une erreur est survenue', null, { duration: 2000, verticalPosition: 'top'});
        break;
    }

    return error;
  }

  private hasCampaign(): boolean {
    return !!(this.campaign);
  }

  public onSubmit() {
    // TODO: delete when fields are alterable
    if (this.purchaseId) {
      this.purchaseDetailForm.get('id').patchValue(this.purchaseId);
    }
    if (this.broadcastStart) {
      this.purchaseDetailForm.get('broadcastStart').patchValue(this.broadcastStart);
    }
    if (this.broadcastEnd) {
      this.purchaseDetailForm.get('broadcastEnd').patchValue(this.broadcastEnd);
    }

    if (this.purchaseDetailForm.valid) {
      this.saving = true;
      this.purchaseService
      .patch(this.purchaseDetailForm.value)
      .subscribe(
        purchase => {
          this.saving = false;
          this.editingCommercial = false;
          this.store.dispatch(new GetPurchaseSuccess(purchase));
          this.snackBar.open(
            'La vente a été modifiée avec succes',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
        },
        error => {
          this.saving = false;
          this.editingCommercial = false;
          this.snackBar.open(
            'Impossible de modifier la vente',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
        }
      );
    }
  }

  public editCommercial(): void {
    this.editingCommercial = !this.editingCommercial;
  }

  private sortPurchaseItems(list) {
    let purchaseItems : Array<any> = [];
    list.display.forEach(function(element) {
      purchaseItems.push(element);
    });
    list.video.forEach(function(element) {
      purchaseItems.push(element);
    });
    list.ops.forEach(function(element) {
      purchaseItems.push(element);
    });
    list.segmentalTv.forEach(function(element) {
      purchaseItems.push(element);
    });
    list.donationTechCost.forEach(function(element) {
      purchaseItems.push(element);
    });
    purchaseItems.sort(function(a, b){
      var a1 = a.purchaseItem.position, b1 = b.purchaseItem.position;
      if (a1 === b1) return 0;
      return a1 > b1 ? 1 : -1;
    });
    return purchaseItems;
  }

  private computeRunningData() {
    this.purchaseItemsList.forEach(item => {
      if (item?.dealProgrammmatic?.runInvoicedAmount) {
        this.runInvoicedAmount += parseFloat(item.dealProgrammmatic.runInvoicedAmount);
      }
    });
  }

  private getPurchaseItemsByType(type) {
     if (Number.isInteger(type)) {
        this.purchaseItemsList = this.sortPurchaseItems(this.purchaseItems).filter(purchaseItem => purchaseItem.purchaseItem.type === type);
     } else {
        this.purchaseItemsList = this.sortPurchaseItems(this.purchaseItems);
     }
  }

  public getAudienceByPurchase () {
    this.exporting = true;
    this.audienceService.getAudiencesByPurchase(this.purchaseId).subscribe(
        (data) => {
          this.getPurchase();
          this.exporting = false;
        },
        (error) => {
          switch (error.status) {
            case 504:
              this.snackBar.open(
                'Veuillez patienter, le traitement est en cours d\'exécution.',
                null,
                {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
              this.exporting = false;
              break;
            default:
              this.snackBar.open(
                'Une erreur est survenue',
                null,
                {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
              this.exporting = false;
              break;
          }
        }
        );
  }
  private duplicate() {
    this.exporting = true;
    this.purchaseService.duplicate(this.purchaseId).subscribe((data) => {
      this.exporting = false;
      this.route.navigate([`/purchase/${data.purchaseId}`]);
    });
  }

  public openReorderDialog() {
      const dialogRef = this.dialog.open(PurchaseDetailReorderDialogComponent, {
        width: '700px',
        disableClose: false,
        data: {
          purchase : this.purchase$,
          items: this.purchaseItemsList
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      // do stuff
    });
  }

  public openBilanModal() {
    const dialogRef = this.dialog.open(PurchaseDetailBilanModalComponent, {
      disableClose: false,
      data: {
        purchaseId : this.purchaseId,
        purchase : this.purchase$,
        broadcastStart: this.broadcastStart,
        broadcastEnd: this.broadcastEnd
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // do stuff
    });
  }

  private startUtAutocomplete(): void {
      this.purchaseDetailForm.get('commercial')
          .valueChanges
          .startWith(null)
          .debounceTime(400)
          .filter(value => typeof value !== 'object')
          .filter(value => value && value.trim().length >= 1)
          .pipe(tap(() => this.loadingUt = true))
          .pipe(tap(() => this.utAutocompleteElem.nativeElement.click()))
          .subscribe(value => {
              this.uts$ = this.utService.getList({name: value})
                  .pipe(tap(() => this.loadingUt = false));
          });
  }

  public displayUt(value) {
      return typeof value !== 'object' || !value ? value : `${value.name} (${value.id})`;
  }

  private initValueChanges() {
    this.signedForm = new FormControl();
    this.signedForm.valueChanges.pipe(
        distinctUntilChanged())
        .subscribe((checked: boolean) => {
          this.savingComercialInfo = true;
          this.currentPurchase.isSigned = checked;
          this.purchaseService.update(this.currentPurchase).subscribe( result => {
            this.savingComercialInfo = false;
          });
        });
  }
  public displayMoreInfo(): void {
    this.setAgreementAndExchangeValues();

    this.editing = !this.editing;
    this.editingHideData = this.editing;
    if (!this.editing) {
      this.leftPanelRowSpan = 5;
      this.allRowHeight = 61;
    } else {
      this.leftPanelRowSpan = 7;
      this.allRowHeight = 70;
    }
  }

  private setAgreementAndExchangeValues() {
    this.purchase$
      .subscribe(
        purchase => {
          if (purchase.campaign && purchase.campaign.channel_agreement) {
            this.channel_agreement = purchase.campaign.channel_agreement ? purchase.campaign.channel_agreement : '';

            switch (this.channel_agreement) {
              case '1':
                this.agreement = true;
                this.exchange = false;
                break;
              case '2':
                this.agreement = false;
                this.exchange = true;
                break;
              case '3':
                this.agreement = true;
                this.exchange = true;
                break;
              case 'N':
                this.agreement = false;
                this.exchange = false;
                break;
              default:
                this.agreement = false;
                this.exchange = false;
                break;
            }
          }
        }
      )
      .unsubscribe();
  }

  private initDealTypes() {
    this.purchaseService.getTypes().subscribe(
      (response) => {
        const dealTypes = response['_embedded']['sell_type'];
        const programmaticDealType = dealTypes.find(
          (type) => ( type.label === 'PROGRAMMATIC' && type.enabled )
        );
        if (programmaticDealType) {
          this.dealTypes = [...this.purchaseService.types, { id: 1, label: 'Achat Programmatique' }];
        } else {
          this.dealTypes = this.purchaseService.types;
        }
      },
      error => console.log('Sell Types', error)
    );
  }
}
